import BasicPageTemplate from '../components/templates/BasicPageTemplate/BasicPageTemplate';
import ErrorPage from './500';
import { useRouter } from 'next/router';
import { getBasicPage } from '../services/contentService';

function CatchAll({ data }) {
    const router = useRouter();
    const slug = router.query.slug || [];

    if (data.error) {
        return <ErrorPage />;
    }

    return <BasicPageTemplate data={data} relativeUrl={`/${slug}`} />;
}

export async function getServerSideProps({ query }) {
    if (query.slug && query.slug.length === 1) {
        const data = await getBasicPage(query.slug[0]);
        return { props: { data }, notFound: data.notFound };
    } else {
        return {
            notFound: true,
        };
    }
}

export default CatchAll;
