import React from 'react';
import PropTypes from 'prop-types';
import { contactEmail } from '../../../utils/constants';

function ContactEmail({ className, ...props }) {
    return (
        <a className={className} href={`tel:${contactEmail}`} {...props}>
            {contactEmail}
        </a>
    );
}

ContactEmail.propTypes = {
    className: PropTypes.string,
};

export default ContactEmail;
