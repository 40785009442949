import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageMetadata from '../../PageMetadata/PageMetadata';
import Hero from '../../Hero/Hero';
import ContactOurTeam from '../../ContactOurTeam/ContactOurTeam';
import Content from '../../Content/Content';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import WhyTheHolidayPeople from '../../WhyTheHolidayPeople/WhyTheHolidayPeople';
import styles from './BasicPageTemplate.module.scss';

function BasicPageTemplate({ data, relativeUrl, className }) {
    return (
        <div className={classNames(styles.basicPageTemplate, className)}>
            <Header className={styles.header} />
            <main>
                <PageMetadata
                    title={data.meta.title}
                    description={data.meta.description}
                    relativeUrl={relativeUrl}
                    imageUrl={data.image}
                />
                <Hero
                    heading={data.heading}
                    subheading={data.subheading}
                    imgSrc={data.image}
                />
                <Content
                    className={styles.content}
                    content={data.content}
                    otherContent={data.otherContent}
                />
            </main>
            <ContactOurTeam className={styles.contactUs} />
            <WhyTheHolidayPeople className={styles.whyUs} />
            <Footer className={styles.footer} />
        </div>
    );
}

BasicPageTemplate.propTypes = {
    className: PropTypes.string,
};

export default BasicPageTemplate;
