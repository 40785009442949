import PropTypes from 'prop-types';
import ContainerTemplate from '../components/templates/ContainerTemplate/ContainerTemplate';
import PageMetadata from '../components/PageMetadata/PageMetadata';
import ContactEmail from '../components/common/ContactEmail/ContactEmail';
import PhoneNumber from '../components/common/PhoneNumber/PhoneNumber';
import { origin } from '../utils/constants';

function ErrorPage() {
    return (
        <ContainerTemplate>
            <PageMetadata
                title="Error - The Holiday People"
                description="Looks like we encountered and unepexted problem."
                imageUrl={`${origin}/images/not-found.jpg`}
                robots="noindex,nofollow"
            />
            <h1>Error</h1>
            <p>Sorry - we encountered a problem while loading this page.</p>
            <p>
                If you need further assistance try calling us on <PhoneNumber />{' '}
                or emailing us at <ContactEmail />.
            </p>
        </ContainerTemplate>
    );
}

ErrorPage.propTypes = {
    statusCode: PropTypes.number.isRequired,
    className: PropTypes.string,
};

export default ErrorPage;
