import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import styles from './ContainerTemplate.module.scss';

function ContainerTemplate({ children, className }) {
    return (
        <div className={classNames(styles.containerTemplate, className)}>
            <Header className={styles.header} />
            <main>{children}</main>
            <Footer className={styles.footer} />
        </div>
    );
}

ContainerTemplate.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default ContainerTemplate;
